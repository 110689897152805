var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mail-right__sent"},[_c('header',{staticClass:"mail-right__item-header d-flex"},[_c('Avatar',{attrs:{"accountId":_vm.from,"size":"60"}}),_c('div',{staticClass:"\n        info\n        pl-30 pl-xl-16 pl-md-16 pl-sm-10\n        flex-grow-1\n        d-flex\n        justify-between\n      "},[_c('div',[_c('div',{staticClass:"name title-20 mb-10 f-700"},[_vm._v(_vm._s(_vm.from))]),_c('div',{staticClass:"to f-500"},[_vm._v("To: "+_vm._s(_vm.to))])]),_c('div',{staticClass:"btnModalForwardReplayContainer"},[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn-sent text-btn-sent cursor-pointer d-flex align-center",on:{"click":_vm.handleForward}},[_c('img',{attrs:{"src":require("../../public/assets/images/sent.svg")}}),_c('span',[_vm._v("Send")])])]),_c('button',{staticClass:"\n            btn-cancelForwardReply btn-sent\n            cursor-pointer\n            d-flex\n            align-center\n            flex-shrink-0\n          ",on:{"click":_vm.handleCancelForward}},[_vm._v(" Cancel ")])])])],1),_c('section',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title title-20 f-700 mb-10"},[_c('div',{staticClass:"textInput-ForwardAndReply"},[_c('div',[_vm._v("Fw:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.titleData),expression:"titleData"}],domProps:{"value":(_vm.titleData)},on:{"input":function($event){if($event.target.composing){ return; }_vm.titleData=$event.target.value}}})])]),_c('div',{staticClass:"description mb-10"},[_c('TipTap',{attrs:{"modelValue":_vm.data,"isDetail":false},on:{"updateModelValue":_vm.updateModelValue}})],1)]),_c('div',{staticClass:"mb-16"},[_c('div',{staticClass:"f-500 mb-16"},[_vm._v("Select message type:")]),_c('div',{staticClass:"near__value-list d-flex"},[_c('div',{staticClass:"near__value-item cursor-pointer",class:{
            active: _vm.type === 'PUBLIC',
          },on:{"click":function($event){_vm.type = 'PUBLIC'}}},[_vm._v(" Public ")]),_c('div',{staticClass:"near__value-item cursor-pointer",class:{
            active: _vm.type === 'PRIVATE',
          },on:{"click":function($event){_vm.type = 'PRIVATE'}}},[_vm._v(" Private ")])])]),_c('div',[_c('div',{staticClass:"f-500"},[_vm._v("Select NEAR to send:")]),_c('div',{staticClass:"near__value-list d-flex"},[_c('div',{staticClass:"near__value-item cursor-pointer",class:{
            active: _vm.amount === 0.05,
          },on:{"click":function($event){_vm.amount = 0.05}}},[_vm._v(" 0.05 NEAR ")]),_c('div',{staticClass:"near__value-item cursor-pointer",class:{
            active: _vm.amount === 0.1,
          },on:{"click":function($event){_vm.amount = 0.1}}},[_vm._v(" 0.1 NEAR ")]),_c('div',{staticClass:"near__value-item cursor-pointer",class:{
            active: _vm.amount === 0.2,
          },on:{"click":function($event){_vm.amount = 0.2}}},[_vm._v(" 0.2 NEAR ")]),_c('div',{staticClass:"near__value-item cursor-pointer",class:{
            active: _vm.amount === 1,
          },on:{"click":function($event){_vm.amount = 1}}},[_vm._v(" 1 NEAR ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }